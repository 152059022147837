.container{
  overflow-x: auto;
  padding-top:1rem;

  .tableContainer{
    overflow-x: scroll;
    .rowHeader th{
      font-weight: bold;
      font-size: 1.15rem;
    }
    
    input{
      min-width: 5rem;
    }
  }
  

.footer {
  display:flex;
  justify-content: flex-end;
}

button{
  margin-top: 2rem;
  margin-right:2rem;
}

}

