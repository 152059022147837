@import "variables";

body{
  color: $font-color-grey;
}

.container{

  .appBar {
    background-color: $dark-blue;
    color: white;

    .suubeeLogo {
      margin-left: 0;
      padding-left: 0;

      img {
        width: 1.5rem;
        height: 1.5rem;
        top: 0.25rem;
        position: relative;
      }

      span {
        text-transform: none;
        color: white;
        font-size: 1.25rem;
      }

      span.grey {
        color: $font-color-grey;
      }
    }

    a {
      color: $bright-green;
    }

    .logout {
      background-color:$bright-blue;
      color:white;
      font-weight: bold;
      border-radius: 5px;
      padding: 0.25rem 0.5rem
    }
  }

}

