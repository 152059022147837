@import "variables";

.container{
  
  h2{
    color: $font-color-blue;
  }
  
  .sectorSelect{
    margin-bottom: 1.5rem;
  }
  
  .select{
    color: white;
    border: $font-color-grey 1px solid;
    max-height: 2rem;
    svg{
        fill: $font-color-grey;
    }
  }
  
  .barCell{
    min-width: 100px;
    padding: 0 !important;
    margin: 0 !important;
  }
  
  .positive{
    color: green;
    text-align: right;
    padding-right: 1rem;
  }
  
  .negative{
    color: red;
    text-align: right;
    padding-right: 1rem;
  }
  
  span{
    color: $font-color-grey;
  }
  
  span.selectedList{
    font-weight: bolder;
    color: white;
  }
  
  .row{
    height: 35px;
    
    td:nth-of-type(3){
      border: none ;
    }
    
    td:nth-of-type(1){
        white-space: nowrap;
    }
  }
}

li.selectorSelect{
  display: block;
  padding-left: 1.2rem;
  margin-top: 0.1rem;
}

