@import "variables";

.container{
  .paperSection{
    border-radius: 1rem;
    margin-top: 1.5rem;
    background-color: $less-dark-blue;
    color: $font-color-grey;
  }
  

}