@import "variables";

.container{
  
  h2 {
    color: $bright-green;
  }
  
  padding-top: 1rem;
  
  .card{
    margin-bottom: 1rem;
    .button{
      border: lightgrey solid 1px;
    }
    .buttonSecondary{
      border: red solid 1px;
      color: red;
      margin-left: 2rem;
      &:disabled{
        color: lightgray;
        border: lightgray solid 1px;
      }
    }

  }
  
  .inviteform{
    button{
      max-width: 15rem;
      margin-left: 1rem;
      margin-top:1rem;
    }
    

    label{
        color: $font-color-grey;
    }
    .textfield{
      margin-top: 0.5rem;
      max-width: 30rem;
      background-color: $dark-blue;
      color: $font-color-grey;
      border: 1px solid $font-color-grey;
    }
    div{
      color: $font-color-grey;
    }
    input{
        background-color: $dark-blue;
        color: $font-color-grey;
      border: 1px solid $font-color-grey;
    }
    
    button{
        background-color: $bright-green;
        color: $text-blue;
        font-weight: bold;
        border-radius: 5px;
        padding: 0.25rem;
    }
  }
  
  .alert{
    background-color: $less-dark-blue;
    color: $font-color-grey;
    a{
        color: $bright-green;
    }
  }
}

li.selectorSelect{
  display: block;
  padding-left: 1.2rem;
  margin-top: 0.1rem;
}