@import "variables";

.container{
  margin: 1rem;
  
  .alert{
    background-color: $less-dark-blue;
    color: $font-color-grey;
    
  }
 
  p.smaller{
    font-size: 1rem;
  }
  
  button.linkButton {
    background: none; 
    color: $bright-green; 
    border: none; 
    padding: 0; 
    font: inherit ;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 0;
  }
  
  button{
    margin-left: 0.5rem;
  }
}