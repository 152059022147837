@import 'variables.scss';

.container {


  .appBar {
    background-color: $dark-blue;
    color: white;

    .suubeeLogo {
      margin-left: 0;
      padding-left: 0;

      img {
        width: 1.5rem;
        height: 1.5rem;
        top: 0.25rem;
        position: relative;
      }

      span {
        text-transform: none;
        color: white;
        font-size: 1.25rem;
      }

      span.grey {
        color: $font-color-grey;
      }
    }

    .loginButton {
      background-color: $bright-green;
      color: $text-blue;
      font-weight: bold;
    }
  }

  .publicShellContainer, {
    background-color: $less-dark-blue;
  }
  
  .footer {
    background-color: $dark-blue;
    color: $font-color-grey;
    
    .footerTitle{
      color: $bright-green;
    }
    
    .footerItem{
      color: $font-color-grey;
    }
  }
}