.container{
  overflow-x: auto;
  
  .rowHeader th{
    font-weight: bold;
  }
  
  .chip{
    margin-left: 0.5rem !important;
  }
}