@import "variables";

.container{
  padding-top:1rem;
  
  color: $font-color-grey;

  .rowHeight{
    min-height: 45px;
  }
  
  .square{
    top: 5px;
    position:relative;
  }

  .slider{
  }
  //
  //.color1{
  //  color:#AAD6A0
  //}
  //
  //.color2{
  //  color:#DCBAA9;
  //}
  //
  //.color3{
  //  color:#94EDFF;
  //}
  //
  //.color4{
  //  color:#0A4C86;
  //}
  //
  //.color5{
  //  color:#1D3B58;
  //}

  .color0{
    color:#D83F87;
  }

  .color1{
    color:#2B89D3;
  }

  .color2{
    color:#FFC300;
  }

  .color3{
    color:#2ECC71;
  }

  .color4{
    color:#9B59B6;
  }

  .color5{
    color:#E67E22;
  }

  .color6{
    color:#D35400;
  }

  .color7{
    color:#7F8C8D;
  }

  .color8{
    color:#C0392B;
  }

  .color9{
    color:#1ABC9C;
  }

  .color10{
    color:#FF00FF;
  }
  
  svg{
    overflow: visible;
  }

  .rowHeader th{
    font-weight: bold;
    background-color: lightgray;
  }
  
  td {
    color: $font-color-grey;
  }
  
  button{
    background-color: #0A4C86;
    color: white;
    border-radius: 5px;
    padding: 0.25rem;
  }
}

