@import "variables";

.container {
  overflow-y: auto;
  
  h2 {
    color: $font-color-blue;
  }

  .tableContainer {
    max-height: 20rem;
    overflow-y: scroll;
  }
  
  li{
    display: flex;
    align-items: center;
  }

  svg {
    &.red {
      color: red;
    }
    &.green {
      color: green;
    }
  }
}