@import "variables";

body{
  background-color: $dark-blue;
  color: $font-color-grey;
  a, title, h1{
    color: $bright-green;
  }
  
  h2{
    color: $font-color-blue;
  }
}

.root {
}
