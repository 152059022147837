.container{
  padding-top: 1.5rem;
  
  div.stepPlaceholder{
    margin-top: 2rem;
  }
  
  div.buttonContainer{
    margin-top: 2rem;
    button {
      margin-left:1rem;
    }
  }
  
  div.stepContainer{

  }
}