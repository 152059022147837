@import "variables";

.container{
  overflow-x: auto;
  
  td, th {
    color: $font-color-grey;
  }
  
  h2{
    color: $font-color-blue;
  }
  
  .tableContainer {
    max-height: 20rem;
    overflow-y: scroll;
  }

  .rowHeader th {
    font-weight: bold;
    position: sticky;
    //stop word wrap
    white-space: nowrap;
    top: 0;
    background-color: $bright-blue; 
    z-index: 1; 
  }

  .tableBody {
    margin-top: 3rem;
  }
}